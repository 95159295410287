docReady(function () {
  const sliders = document.querySelectorAll(".glide");
  const pathname = window.location.pathname;
  const desktopNavLinks = document.querySelectorAll(
    ".main-navigation__navbar__links a"
  );
  const mobileNavLinks = document.querySelectorAll(
    ".main-navigation__mobile-menu__links a"
  );

  Iodine.setErrorMessages({
    required: "Bitte füllen Sie dieses Pflichtfeld aus.",
    email: "Bitte geben Sie eine gültige E-Mail-Adresse an.",
  });

  if (sliders && Glide) {
    const glideOptions = getGlideOptions();
    sliders.forEach((slider) => {
      new Glide(slider, glideOptions).mount();
    });
  }

  resetNavActive();
  setNavActive(pathname);

  function resetNavActive() {
    desktopNavLinks.forEach((link) => {
      link.classList.remove("active");
    });

    mobileNavLinks.forEach((link) => {
      link.classList.remove("active");
    });
  }

  function setNavActive(path) {
    desktopNavLinks.forEach((link) => {
      if (link.getAttribute("href") === path) {
        link.classList.add("active");
      }
    });

    mobileNavLinks.forEach((link) => {
      if (link.getAttribute("href") === path) {
        link.classList.add("active");
      }
    });
  }

  function getGlideOptions() {
    return {
      type: "slider",
      startAt: 0,
      perView: 1,
      rewind: false,
      keyboard: true,
      bound: false,
      autoplay: false,
      hoverpause: true,
      gap: 0,
      focusAt: 0,
      peek: {
        before: 0,
        after: 0,
      },
    };
  }
});
