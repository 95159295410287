import Alpine from "alpinejs";
import Scroll from "@alpine-collective/toolkit-scroll";
import Screen from "@alpine-collective/toolkit-screen";
import accordion from "./accordion";
import comingSoonFeatures from "./comingSoonFeatures";
import contactForm from "./contactForm";
import contactWizard from "./contactWizard";
import hotspots from "./hotspots";
import menu from "./menu";
import steps from "./steps";
import tabs from "./tabs";

Alpine.plugin(Scroll);
Alpine.plugin(Screen);

Alpine.data("accordion", accordion);
Alpine.data("comingSoonFeatures", comingSoonFeatures);
Alpine.data("contactForm", contactForm);
Alpine.data("contactWizard", contactWizard);
Alpine.data("hotspots", hotspots);
Alpine.data("menu", menu);
Alpine.data("steps", steps);
Alpine.data("tabs", tabs);

Alpine.start();
