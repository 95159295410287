export default () => ({
  buttonLabel: "Absenden",
  isDisabled: true,
  loading: false,
  formSuccessful: false,
  formFailed: false,
  formElements: [],
  init() {
    Iodine.addRule("requiredCheckbox", (value, name) => {
      return document.querySelector('input[name="' + name + '"][type="checkbox"]:checked') != null;
    });
    //Store an array of all the input elements with 'data-rules' attributes
    this.formElements = [...this.$el.querySelectorAll("[data-rules]")];
    this.initDomData();
    this.updateErrorMessages();
  },
  initDomData: function () {
    //Create an object attached to the component state for each input element to store its state
    this.formElements.map((element) => {
      this[element.name] = {
        blurred: false,
      };
    });
  },
  updateErrorMessages: function () {
    //map throught the input elements and set the 'errorMessage'
    this.formElements.map((element) => {
      this[element.name].errorMessage = this.getErrorMessage(element);
    });
  },
  getErrorMessage: function (element) {
    //Check using iodine and return the error message only if the element has not been blurred
    const error = Iodine.is(element.value, JSON.parse(element.dataset.rules));
    if (error !== true && this[element.name].blurred) {
      return Iodine.getErrorMessage(error);
    }
    //return empty string if there are no errors
    return "";
  },
  change: function (event) {
    //Ignore all events that aren't coming from the inputs we're watching
    if (!this[event.target.name]) {
      return false;
    }
    if (event.type === "focusout") {
      this[event.target.name].blurred = true;
    }
    const invalidElements = this.formElements.filter((element) => {
      return Iodine.is(element.value, JSON.parse(element.dataset.rules)) !== true;
    });
    if (invalidElements.length === 0) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
    //Whether blurred or on input, we update the error messages
    this.updateErrorMessages();
  },
  formData: {
    type: "contact",
    name: "",
    email: "",
    message: "",
    website: "",
  },
  submitData() {
    this.buttonLabel = "Wird gesendet...";
    this.loading = true;
    this.isDisabled = this.loading;

    fetch("contact.php", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.formSuccessful = true;
        } else {
          this.formFailed = true;
        }

        this.message = data.message;
      })
      .catch((error) => {
        console.error(error);
        this.formFailed = true;
      })
      .finally(() => {
        this.buttonLabel = "Absenden";
        this.loading = false;
        this.isDisabled = this.loading;
      });
  },
});
