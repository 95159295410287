export default () => ({
  currentStep: 0,
  isSubmitDisabled: true,
  showProgressBar: false,
  formSuccessful: false,
  formFailed: false,
  showNextButton: false,
  disabledNextButton: true,
  message: "",
  formElements: [],
  errorMessages: {
    email: "",
  },
  steps: [
    {
      title: "Ich wünsche mir eine kostenlose...",
      name: "requests",
      type: "radio",
      options: [
        {
          label: "Vorstellung im Rahmen eines Webinars",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg>',
        },
        {
          label: "Demo zum selbst ausprobieren",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>',
        },
      ],
    },
    {
      title: "Meine Kontaktdaten sind",
      // text: "Jetzt noch schnell Ihre E-Mail-Adresse hinterlassen und schon bald mehr über den Education Manager erfahren.",
      name: "contact",
      type: "contact",
      fields: [
        {
          name: "email",
          label: "E-Mail-Adresse *",
          autocomplete: "email",
          type: "email",
          size: "full",
          dataRules: '["required", "email"]',
        },
        {
          name: "phone",
          label: "Telefonnummer",
          autocomplete: "phone number",
          type: "phone",
          size: "full",
        },
      ],
    },
    {
      title:
        "Um auf Ihre Anfrage so konkret wie möglich eingehen zu können, freuen wir uns, wenn Sie uns noch fünf Fragen beantworten.",
      name: "questions",
      type: "text",
      buttonLabel: "Los geht's",
    },
    {
      title: "Sie sind?",
      name: "organizationType",
      type: "radio",
      options: [
        {
          label: "Öffentliche Hochschule",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path d="M12 14l9-5-9-5-9 5 9 5z" /><path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" /></svg>',
        },
        {
          label: "Private Hochschule",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" viewBox="0 0 20 20" fill="currentColor"><path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" /></svg>',
        },
        {
          label: "Öffentliche Akademie",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>',
        },
        {
          label: "Private Akademie",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd" /><path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" /></svg>',
        },
        {
          label: "Volkshochschule",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" /></svg>',
        },
        {
          label: "Sonstiges",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',
          showOther: true,
        },
      ],
      other: {
        value: "Sonstiges",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__otherIcon" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',
        data: "organizationTypeOther",
        placeholder: "z.B. eingetragener Verein",
      },
    },
    {
      title: "Wie viele Veranstaltungen führen Sie pro Monat in etwa durch?",
      name: "eventCount",
      type: "radio",
      options: [
        {
          label: "weniger als 50",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" /></svg>',
        },
        {
          label: "50 bis 500",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" /></svg>',
        },
        {
          label: "mehr als 500",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" /></svg>',
        },
      ],
    },
    {
      title:
        "Nutzen Sie bereits ein System zur Verwaltung Ihrer Veranstaltungen?",
      name: "existingSoftware",
      type: "radio",
      options: [
        {
          label: "Nein",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5" /></svg>',
        },
        {
          label: "Ja",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" /></svg>',
          showOther: true,
        },
      ],
      other: {
        value: "Ja",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__otherIcon" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" /></svg>',
        data: "existingSoftwareOther",
        placeholder: "Welches System?",
      },
    },
    {
      title: "Wie führen Sie den Großteil Ihrer Veranstaltungen durch?",
      name: "eventLocation",
      type: "radio",
      options: [
        {
          label: "Vor Ort",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" /></svg>',
        },
        {
          label: "Online",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.111 16.404a5.5 5.5 0 017.778 0M12 20h.01m-7.08-7.071c3.904-3.905 10.236-3.905 14.141 0M1.394 9.393c5.857-5.857 15.355-5.857 21.213 0" /></svg>',
        },
        {
          label: "Etwa 50:50 vor Ort und online",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" /></svg>',
        },
      ],
    },
    {
      title:
        "Wie sollen sich Interessierte für Ihre Bildungsangebote anmelden?",
      name: "registration",
      type: "checkbox",
      hint: "(Mehrfachauswahl möglich)",
      options: [
        {
          label: "online auf bestehender Website",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" /></svg>',
        },
        {
          label: "online auf neuer Website",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>',
        },
        {
          label: "auf dem Postweg",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" /></svg>',
        },
        {
          label: "vor Ort",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" class="contactWizard__step__button__icon group-hover:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" /></svg>',
        },
      ],
    },
    {
      title: "Vielen Dank!",
      text: "Wir melden uns in den kommenden Tagen mit einem individuellen Angebot bei Ihnen.",
      name: "thankYou",
      type: "text",
    },
  ],
  formData: {
    timestamp: new Date(),
    type: "contactWizard",
    organizationType: "",
    eventCount: "",
    existingSoftware: "",
    eventLocation: "",
    registration: [],
    requests: "",
    email: "",
    phone: "",
    questions: "",
    thankYou: "",
    firestoreDocId: "",
  },
  initFormData() {
    this.formElements = [
      ...document.querySelectorAll(".contactWizard [data-rules]"),
    ];
    this.initDomData();
    this.updateErrorMessages();

    Iodine.setErrorMessage("email", "Bitte gültige E-Mail Adresse eingeben");
  },
  initDomData: function () {
    this.formElements.map((element) => {
      this[element.name] = {
        blurred: false,
      };
    });
  },
  updateErrorMessages: function () {
    this.formElements.map((element) => {
      this.errorMessages[element.name] = this.getErrorMessage(element);
    });
  },
  getErrorMessage: function (element) {
    const error = Iodine.is(element.value, JSON.parse(element.dataset.rules));
    if (error !== true && this[element.name].blurred) {
      return Iodine.getErrorMessage(error);
    }
    return "";
  },
  change: function (event) {
    if (!this[event.target.name]) {
      return false;
    }
    if (event.type === "focusout") {
      this[event.target.name].blurred = true;
    }
    const invalidElements = this.formElements.filter((element) => {
      return (
        Iodine.is(element.value, JSON.parse(element.dataset.rules)) !== true
      );
    });
    if (invalidElements.length === 0) {
      this.isSubmitDisabled = false;
    } else {
      this.isSubmitDisabled = true;
    }
    this.updateErrorMessages();
  },
  displayNextButton(disabled) {
    this.showNextButton = true;
    disabled
      ? (this.disabledNextButton = true)
      : (this.disabledNextButton = false);
  },
  hideNextButton() {
    this.showNextButton = false;
  },
  // disableNextButton() {
  //   this.disabledNextButton = true;
  // },
  // enableNextButton() {
  //   this.disabledNextButton = false;
  // },
  isNextButtonDisabled(step) {
    if (step && step.other) {
      if (this.formData[step.other.data]) {
        if (this.formData[step.other.data].length === 0) {
          return true;
        } else {
          return false;
        }
      }
    }
    return this.disabledNextButton === true;
  },
  isPreviousButtonShown(step, index) {
    if (step && step.other) {
      if (this.formData[step.name] !== step.other.value) {
        if (index > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      if (
        this.currentStep > 0 &&
        this.currentStep != 2 &&
        this.currentStep < 8
      ) {
        return true;
      }
    }
  },
  isSubmitButtonShown(collection) {
    return collection.length - 2 === this.currentStep || this.currentStep === 1;
  },

  isCustomButtonShown() {
    return this.currentStep === 2;
  },

  isTextShown() {
    return this.currentStep === 8;
  },

  isNextButtonShown(step) {
    return (
      this.showNextButton &&
      this.formData[step.name] &&
      this.formData[step.name].length > 0
    );
  },

  handleNextStep(step, option) {
    this.initFormData();

    if (step && step.other) {
      if (this.formData[step.name] === step.other.value) {
        this.displayNextButton(true);
      } else if (!option.showOther) {
        this.$scroll("#contact-wizard");
        this.increaseStepCount();
      }
    } else if (step.type === "checkbox") {
      this.hideNextButton();
    } else {
      this.$scroll("#contact-wizard");
      this.increaseStepCount();
    }

    if (step.type !== "checkbox") {
      // Prevent submitting when checkbox item is selected
      this.submitToFirestore();
    }
  },
  handlePreviousStep(step, index, collection) {
    if (step && step.other) {
      if (this.formData[step.name] !== step.other.value) {
        this.decreaseStepCount();
        this.hideNextButton();
      } else {
        this.formData[step.name] = "";
        this.formData[step.other.data] = "";
      }
    } else {
      if (this.currentStep > 0) {
        this.decreaseStepCount();
        this.hideNextButton();
      }
    }

    if (index > 0) {
      if (Array.isArray(this.formData[collection[index - 1].name])) {
        this.formData[collection[index - 1].name] = [];
      } else {
        this.formData[collection[index - 1].name] = "";
      }
    } else {
      if (Array.isArray(this.formData[step.name])) {
        this.formData[step.name] = [];
      } else {
        this.formData[step.name] = "";
      }
    }
  },
  increaseStepCount(by) {
    var increaser = by ? by : 1;
    this.currentStep += increaser;
  },
  decreaseStepCount(by) {
    var decreaser = by ? by : 1;
    this.currentStep -= decreaser;
  },

  submitData() {
    this.buttonLabel = "Wird gesendet...";
    this.loading = true;
    this.isSubmitDisabled = this.loading;
    this.formData.firestoreDocId = sessionStorage.getItem("firestoreDocId");

    fetch("contact.php", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.formSuccessful = true;
        } else {
          this.formFailed = true;
        }

        this.message = data.message;
      })
      .catch((error) => {
        console.error(error);
        this.formFailed = false;
      })
      .finally(() => {
        this.buttonLabel = "Absenden";
        this.loading = false;
        this.isSubmitDisabled = this.loading;
        this.handleNextStep(this.steps[this.currentStep + 1], {});
      });
  },
  submitToFirestore() {
    var db = firebaseApp.firestore();
    var firestoreDocId = sessionStorage.getItem("firestoreDocId");

    firebaseApp
      .auth()
      .signInAnonymously()
      .then(() => {
        if (!firestoreDocId) {
          db.collection("contact-wizard")
            .add(this.formData)
            .then((docRef) => {
              sessionStorage.setItem("firestoreDocId", docRef.id);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
        } else {
          db.collection("contact-wizard")
            .doc(firestoreDocId)
            .set(this.formData)
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error authenticating to Firebase: ", error);
      });
  },
});
