export default () => ({
  showMobileMenu: false,
  openMobileMenu() {
    this.showMobileMenu = true;
  },
  closeMobileMenu() {
    this.showMobileMenu = false;
  },
  isOpenMobileMenu() {
    return this.showMobileMenu === true;
  },
});
