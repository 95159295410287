import "./util.js";
import "./main.js";
import "@kingshott/iodine";
import "./alpine/index";
import "./plausible.js";
import "../css/tailwind.css";
import "../scss/main.scss";
import Glide from "@glidejs/glide";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: "edm-landing-page.firebaseapp.com",
  projectId: "edm-landing-page",
  storageBucket: "edm-landing-page.appspot.com",
  messagingSenderId: "1085783582214",
  appId: "1:1085783582214:web:6245da877fd0601db4c587",
};

window.firebaseApp = firebase.initializeApp(firebaseConfig);

window.Glide = Glide;
