// packages/$screen/src/index.js
function src_default(Alpine) {
  let data = Alpine.reactive({screensize: window.innerWidth});
  const defaultBreakpoints = {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    "2xl": 1536
  };
  const breakpoints = window.AlpineMagicHelpersConfig && window.AlpineMagicHelpersConfig.breakpoints ? window.AlpineMagicHelpersConfig.breakpoints : defaultBreakpoints;
  let update;
  window.addEventListener("resize", () => {
    clearTimeout(update);
    update = setTimeout(() => data.screensize = window.innerWidth, 150);
  });
  Alpine.magic("screen", (el) => (breakpoint) => {
    let width = data.screensize;
    if (Number.isInteger(breakpoint))
      return breakpoint <= width;
    if (breakpoints[breakpoint] === void 0) {
      throw Error("Undefined $screen property: " + breakpoint + ". Supported properties: " + Object.keys(breakpoints).join(", "));
    }
    return breakpoints[breakpoint] <= width;
  });
}

// packages/$screen/builds/module.js
var module_default = src_default;
export {
  module_default as default
};
