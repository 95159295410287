export default () => ({
  activeHotspot: null,
  interactedWithHotspots: false,
  setActiveHotspot(index) {
    this.isActiveHotspot(index) ? (this.activeHotspot = null) : (this.activeHotspot = index);
  },
  isActiveHotspot(index) {
    return this.activeHotspot === index;
  },
  setInteractedWithHotspot() {
    this.interactedWithHotspots = true;
  },
});
