"use strict";

var winHeight, docHeight, trackLength, throttleScroll;
var scrollMilestones = [];

docReady(function () {
  // Get initial page measurements for scroll tracking
  getPageMeasurements();
  var trackableElements = document.querySelectorAll("[data-track-event]");

  if (trackableElements.length > 0) {
    for (var i = 0; i < trackableElements.length; i += 1) {
      trackableElements[i].addEventListener("click", function () {
        var event = this.getAttribute("data-track-event");
        var propsToTrack = this.getAttribute("data-track-props");
        var propsArray = [];
        var props = {};

        // split props attribute
        if (typeof propsToTrack !== "undefined") {
          var tmpProps = "";
          propsArray = propsToTrack.split(";");

          for (var i = 0; i < propsArray.length; i += 1) {
            tmpProps = propsArray[i].split(":");
            var propKey = tmpProps[0];
            var propValue = tmpProps[1];
            props[propKey] = propValue;
          }
        }

        if (typeof plausible === "function") {
          if (isEmptyObject(props)) {
            plausible(event);
          } else {
            plausible(event, { meta: props });
          }
        }
      });
    }
  }
});

function getPageMeasurements() {
  winHeight = window.screen.height;
  docHeight = document.body.clientHeight;
  trackLength = docHeight - winHeight;
}

function getAmountScrolled() {
  var scrollTop =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0;
  var percentageScrolled = Math.floor((scrollTop / trackLength) * 100);

  if (percentageScrolled >= 25) {
    trackAmountScrolled("25");
  }
  if (percentageScrolled >= 50) {
    trackAmountScrolled("50");
  }
  if (percentageScrolled >= 75) {
    trackAmountScrolled("75");
  }
  if (percentageScrolled >= 90) {
    trackAmountScrolled("90");
  }
}

function trackAmountScrolled(scrollingMilestone) {
  if (scrollMilestones.indexOf(scrollingMilestone) === -1) {
    scrollMilestones.push(scrollingMilestone);
    if (typeof plausible === "function") {
      plausible("Scrolling", {
        meta: { percentage: scrollingMilestone + "%" },
      });
    }
  }
}

window.addEventListener("scroll", function () {
  // Throttle polling
  clearTimeout(throttleScroll);
  throttleScroll = setTimeout(function () {
    getAmountScrolled();
  }, 50);
});

// Update page measurements if window is resized
window.addEventListener("resize", function () {
  getPageMeasurements();
});
